import { z } from 'zod'

import { logger } from '@publica/ui-common-logger'

import { documentReady } from '../env'
import { runAsync } from '../run'
import { MethodCall, MethodCallPayload, RequestChannel, ResponseChannel } from './protocol'

let clientSerial = 0

type RequestSideHandler = (result: Office.DialogParentMessageReceivedEventArgs) => void

const jsonLiteralSchema = z.union([z.null(), z.number(), z.boolean(), z.string()])
const jsonSchema: z.ZodSchema<MethodCallPayload> = z.lazy(() =>
    z.union([jsonLiteralSchema, z.array(jsonSchema), z.record(jsonSchema)])
)

const commonSchema = z.object({
    clientSerial: z.number(),
    serial: z.number(),
    method: z.string(),
    payload: jsonSchema.optional(),
})

const requestSchema = z.intersection(
    z.object({
        type: z.literal('request'),
    }),
    commonSchema
)

type Request = z.infer<typeof requestSchema>

const responseSchema = z.intersection(
    z.object({
        type: z.literal('response'),
    }),
    commonSchema
)

const errorSchema = z.intersection(
    z.object({
        type: z.literal('error'),
    }),
    commonSchema
)

const responseOrErrorSchema = z.union([responseSchema, errorSchema])

type ResponseOrError = z.infer<typeof responseOrErrorSchema>

const defaultTimeout = 90000

type OfficeRequestChannelOptions = {
    timeout?: number | false
}

export class OfficeRequestChannel extends RequestChannel<OfficeRequestChannelOptions> {
    private readonly requestMap: Map<number, (responseOrError: ResponseOrError) => void>
    private serial = 0
    private clientSerial: number
    private handlerSet = false

    constructor() {
        super()

        this.requestMap = new Map()
        this.clientSerial = clientSerial++
    }

    async send({ method, payload }: MethodCall, options?: OfficeRequestChannelOptions): Promise<MethodCall> {
        await this.registerHandler()
        const serial = this.serial++
        const { clientSerial } = this

        const request: Request = {
            type: 'request',
            serial,
            method,
            payload,
            clientSerial,
        }

        const reqSerialized = JSON.stringify(request)

        const reqPromise = new Promise<MethodCall>((resolve, reject) => {
            this.requestMap.set(serial, response => {
                logger.debug('received response', { payload: { response } })

                this.requestMap.delete(serial)

                if (response.type === 'response') {
                    resolve(response)
                } else {
                    logger.error('request failed', { payload: { response } })
                    reject(new Error('Request failed'))
                }
            })
        })

        logger.debug('sending request', { payload: { request } })

        Office.context.ui.messageParent(reqSerialized)

        const timeout = options?.timeout

        if (timeout !== false) {
            const timeoutPromise = new Promise<MethodCall>((_, reject) => {
                setTimeout(() => reject(new Error('Request timeout')), timeout ?? defaultTimeout)
            })

            return Promise.race([reqPromise, timeoutPromise])
        }

        return reqPromise
    }

    private async handleEvent({ message }: Office.DialogParentMessageReceivedEventArgs): Promise<void> {
        const payload = JSON.parse(message)
        const response = responseOrErrorSchema.parse(payload)

        // If the serial doesn't match, it's not for us
        if (this.clientSerial !== response.clientSerial) {
            return
        }

        const handler = this.requestMap.get(response.serial)

        if (handler === undefined) {
            logger.error('No handler found for incoming message', { payload: { response, handlers: this.requestMap } })
            throw new Error('No handler found for incoming message')
        }

        handler(response)
    }

    private async registerHandler() {
        const handler: RequestSideHandler = result => {
            void this.handleEvent(result)
        }

        if (!this.handlerSet) {
            // It's important that the flag be set before the async call, or there's a
            // concurrency bug where the handler is set twice
            this.handlerSet = true
            await documentReady
            // eslint-disable-next-line @typescript-eslint/unbound-method
            await runAsync(Office.context.ui.addHandlerAsync, Office.EventType.DialogParentMessageReceived, handler, {})
        }
    }
}

type OfficeDialogMessage =
    | {
          message: string
      }
    | {
          error: number
      }

export class OfficeResponseChannel extends ResponseChannel {
    constructor(private readonly dialog: Office.Dialog) {
        super()

        this.dialog.addEventHandler(Office.EventType.DialogMessageReceived, event => {
            void this.handleEvent(event)
        })
    }

    private async handleEvent(event: OfficeDialogMessage) {
        if ('error' in event) {
            throw new Error(`Error received by parent: ${event.error}`)
        }

        const message = JSON.parse(event.message)
        const request = requestSchema.parse(message)
        const handler = this.handlers[request.method]

        logger.debug('received request', { payload: { request } })

        let response: ResponseOrError

        if (handler === undefined) {
            response = {
                ...request,
                type: 'error',
                payload: 'No corresponding handler',
            }

            logger.debug('sending missing handler response', { payload: { response } })
        } else {
            try {
                response = {
                    ...request,
                    type: 'response',
                    payload: await handler(request),
                }

                logger.debug('sending response', { payload: { response } })
            } catch (error) {
                logger.error('Error handling request', { error })

                response = {
                    ...request,
                    type: 'error',
                    payload: (error as Error).message,
                }

                logger.debug('sending error response', { payload: { response } })
            }
        }

        this.dialog.messageChild(JSON.stringify(response))
    }
}
